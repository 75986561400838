@font-face {
    font-family: IRANSansfanum;
    font-style: normal;
    font-weight: 500;
    src: url('./asset/fonts/iransansfanum/eot/IRANSansWeb(FaNum)_Medium.eot');
    src: url('./asset/fonts/iransansfanum/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('./asset/fonts/iransansfanum/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('./asset/fonts/iransansfanum/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./asset/fonts/iransansfanum/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
}
@font-face {
    font-family: Tahoma;
    font-style: normal;
    font-weight: 500;
    src: url('./asset/fonts/iransansfanum/eot/IRANSansWeb(FaNum)_Medium.eot');
    src: url('./asset/fonts/iransansfanum/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
    url('./asset/fonts/iransansfanum/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
    url('./asset/fonts/iransansfanum/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
    url('./asset/fonts/iransansfanum/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
}


body {
    font-family: IRANSansfanum;
}
.accordion-buttonNew {
  overflow-anchor: none;
}
.accordion-buttonNew:not(.collapsed) {
  box-shadow: inset 0 -1px 0 #e5e7eb;
}
.accordion-buttonNew:not(.collapsed)::after {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.accordion-buttonNew::after {
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.accordion-buttonNew:hover {
  z-index: 2;
}
.accordion-buttonNew:focus {
  z-index: 3;
}
.accordion-item:first-of-type .accordion-buttonNew {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.accordion-item:last-of-type .accordion-buttonNew.collapsed {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
      -webkit-transition: none;
      transition: none;
  }
  .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none;
  }
  .form-switch .form-check-input {
      -webkit-transition: none;
      transition: none;
  }
  .form-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none;
  }
  .form-range::-moz-range-thumb {
      -webkit-transition: none;
      transition: none;
  }
  .form-floating > label {
      -webkit-transition: none;
      transition: none;
  }
  .fade {
      -webkit-transition: none;
      transition: none;
  }
  .collapsing {
      -webkit-transition: none;
      transition: none;
  }
  .collapsing.collapse-horizontal {
      -webkit-transition: none;
      transition: none;
  }
  .accordion-buttonNew::after {
      -webkit-transition: none;
      transition: none;
  }
  .modal.fade .modal-dialog {
      -webkit-transition: none;
      transition: none;
  }
  .carousel-item {
      -webkit-transition: none;
      transition: none;
  }
  .carousel-fade .active.carousel-item-start {
      -webkit-transition: none;
      transition: none;
  }
  .carousel-fade .active.carousel-item-end {
      -webkit-transition: none;
      transition: none;
  }
  .carousel-control-prev {
      -webkit-transition: none;
      transition: none;
  }
  .carousel-control-next {
      -webkit-transition: none;
      transition: none;
  }
  .carousel-indicators [data-bs-target] {
      -webkit-transition: none;
      transition: none;
  }
  .spinner-border {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
  }
  .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
  }
}



.modal-header .btn-close {
    padding: unset;
    margin: unset;
}


.form-select{
    background-position: left 0.75rem center !important; 
}

.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 450px;  
}
  
    
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
  

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background: #007BFF;
  }
  